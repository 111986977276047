import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { ProgramContentType, ProgramItemType, ProgramTaskType } from '../types/Program';
import { getProgramItemId } from '../utils/programUtils';
import { MarkdownBlock } from '../components/MarkdownBlock';
import './ProgramItem.css';
import { Collapse } from '../components/Collapse';


export function ProgramItem(props: { content: ProgramContentType, tasks: ProgramTaskType[] }) {
  const navigate = useNavigate();
  const { content, tasks } = props;

  const [markdownContent, setMarkdownContent] = useState('');

  useEffect(() => {
    // tmp: Fetch the Markdown file content
    const filePath = `/tmpMarkdown/${content.item_id}.md`;
    fetch(filePath)
      .then((response) => response.text())
      .then((text) => {
        if (text && text.indexOf('<!DOCTYPE html>') !== 0) setMarkdownContent(text)
      })
      .catch((error) => console.error('Error loading Markdown file:', error));
  }, [content]);

  function handleSelect(item: ProgramContentType) {
    const newUrl = `/program/${item.item_id}`;
    navigate(newUrl);
  }

  const itemTasks = tasks.filter(t => t.item_id === content.item_id);

  const clExtra = {
    'program-item__extra': 1,
    'program-item__extra_empty': !itemTasks.length,
  };

  return (
    <div className="program-item" id={'itemcontent' + content.item_id}>
      <div className="program-item__main">
        <div className="program-item__title" id={getProgramItemId(content.item_id)} onClick={() => handleSelect(content)}>
          {content.item_prefix} {content.title} 
        </div>
        <div className="program-item__content">
          {/* content.content */}
          <MarkdownBlock>{markdownContent}</MarkdownBlock>
        </div>
      </div>
      <div className={classNames(clExtra)}>
        {itemTasks.map((t, index) => <ProgramTask task={t} taskCode={`${content.item_prefix}${index + 1}`} />)}
      </div>
    </div>
  );
}

export function ProgramTask(props: { task: ProgramTaskType, taskCode: string }) {
  const { task, taskCode } = props;
  const { content, id, item_id, secondaryContent, imageUrl } = task;

  const [markdownContent, setMarkdownContent] = useState('');

  useEffect(() => {
    // tmp: Fetch the Markdown file content
    const filePath = `/tmpMarkdownTasks/${id}.md`;
    fetch(filePath)
      .then((response) => response.text())
      .then((text) => {
        if (text && text.indexOf('<!DOCTYPE html>') !== 0) setMarkdownContent(text)
      })
      .catch((error) => console.error('Error loading Markdown file:', error));
  }, [content]);

  return (
    <div className="program-task">
      <div className="program-task__title">
        Task {taskCode}
      </div>
      <div className="program-task__content">
        {content || <MarkdownBlock className='markdown-block_task'>{markdownContent}</MarkdownBlock>}
        {imageUrl && (
          <Collapse label="View">
            <div className="markdown-image_fullwidth">
              <img width="100%" src={imageUrl} />
            </div>
          </Collapse>
        )}
      </div>
    </div>
  );
}

export function ProgramSectionTitle(props: { item: ProgramItemType }) {
  const { item } = props;

  return (
    <div className="program-item">
      <div className="program-item__main">
        <div className="program-section-title" id={getProgramItemId(item.id)}>
          {item?.title}
        </div>
      </div>
      <div className="program-item__extra program-item__extra_empty">
      </div>
    </div>
  );
}
