import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { Icon } from '../components/Icon';
import { ProgramContentType, ProgramItemType, ProgramTaskType } from '../types/Program';
import { MenuConfigType } from '../types/Config';
import { ProgramMenugroup } from './ProgramMenu';
import { ProgramItem, ProgramSectionTitle } from './ProgramItem';
import { getProgramItemId } from '../utils/programUtils';
import './Program.css';
// tmp
import { example_program_content, program_item_empty } from '../consts/exampleData';
import { example_tasks_content } from '../consts/exampleDataTasks';


/*
Temporary:
1-100 numbers will be reserved for section/topic ids
so you can use parent_id in title
Item ids will start from 101
*/

export function Program(props: { menuItems: ProgramItemType[], menuConfig: MenuConfigType[] }) {
  const { menuItems, menuConfig } = props;
  const navigate = useNavigate();
  const { itemId: urlItemId = 1 } = useParams();
  const [parentId, setParentId] = useState<number>();
  const [menuOpen, setMenuOpen] = useState<boolean>(true);
  const [itemsContent, setItemsContent] = useState<ProgramContentType[]>([]);
  const [tasks, setTasks] = useState<ProgramTaskType[]>([]);

  async function loadContent(parentId : number) {
    // load content for one section/topic only

    let content = example_program_content;
    content = menuItems.filter(item => item.parent_id === parentId).map((item, index) => {
      const itemContent = content.find(c => c.item_id === item.id) || { item_id: item.id, content: '' };
      const itemPrefix = `${item.parent_id}.${index + 1}.`;
      return { ...itemContent, title: item.title, item_prefix: itemPrefix };
    });

    setItemsContent(content);
    const tasksData = example_tasks_content;
    setTasks(tasksData);
  }

  async function loadShowItem(newItem: ProgramItemType) {
    const newParentId = newItem.parent_id || newItem.id;

    if (newParentId !== parentId || !itemsContent.length) {
      setParentId(newParentId);
      await loadContent(newParentId);
    }

    setTimeout(() => {
      // el is null without timeout
      const el = document.getElementById(getProgramItemId(newItem.id));
      if (el && newItem.id !== newParentId) {
        el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

        // Highlight the item for 2 seconds
        el.classList.add('highlight');
        setTimeout(() => {
          el.classList.remove('highlight');
        }, 2000);
      }
    }, 0)
  }

  useEffect(() => {
    const item = menuItems.find(item => item.id === Number(urlItemId)) || menuItems[0] || program_item_empty;
    loadShowItem(item);
  }, [menuItems, urlItemId]);

  function toggleMenuopen() {
    setMenuOpen(!menuOpen);
  }

  const clProgram = {
    'program': 1,
    'program_open': menuOpen,
    'program_closed': !menuOpen,
  };

  const menugroups = menuItems.filter(item => item.parent_id === null);
  const parentSection = menuItems.find(item => item.id === parentId) || program_item_empty;

  return (
    <>

      <div className={classNames(clProgram)}>
        <div className="program-left">
          <div className="homelink" onClick={() => navigate('/program/11')}>
            <img className="homelink__logo" src="/logo192.png" />
            {menuOpen && <div className="homelink__text">DevClass</div>}
          </div>
          <div className="program-menu-toggle" onClick={toggleMenuopen}>
            {menuOpen ? <Icon name="keyboard_double_arrow_left"/> : <Icon name="keyboard_double_arrow_right"/>}
          </div>
          <div className="program-menu">
            {menuOpen && menugroups.map(item => <ProgramMenugroup item={item} allItems={menuItems} key={item.id} menuConfig={menuConfig} />)}
          </div>
        </div>
        <div className="program-center">

          <div className="program-nav">
            <div className="program-nav__main">
              <div className="program-search">
                <Icon name="search" />
              </div>
            </div>
            <div className="program-nav__extra">
              <div className="program-extra-bkg"></div>
            </div>
          </div>
          
          <ProgramSectionTitle item={parentSection} />
          {itemsContent.map(content => <ProgramItem content={content} key={content.item_id} tasks={tasks} />)}
        </div>
      </div>
    </>
  );
}


/* 
program

- program-left
- - homelink
- - - homelink__logo
- - - homelink__text
- - program-menu-toggle
- - program-menu
- - - program-menugroup
- - - - program-menugroup__title
- - - - program-menugroup__items
- - - - - program-menugroup__item
- - -

- program-center
- - program-nav
- - - program-nav__main
- - - program-nav__extra

- - program-item
- - - program-item__main
- - - - program-item__title
- - - - program-item__content
- - - 
- - - program-item__extra
- - - - program-task
- - - - - program-task__title
- - - - - program-task__content
*/