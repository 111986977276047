import React from 'react';
import classNames from 'classnames';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight, atomDark, solarizedDarkAtom, vscDarkPlus, oneDark, duotoneDark, coldarkDark, okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { atomOneDarkReasonable, dark, kimbieDark, solarizedDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { atomOneDark, darkula } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
// import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './CodeBlock.css';


export function CodeBlock({ language, children }: { language: string, children: string }) {
  const cl = {
    [language]: 1,
    'code-block': 1,
  };

  return (
    <SyntaxHighlighter language={language} style={vscDarkPlus} className={classNames(cl)}>
      {children}
    </SyntaxHighlighter>
  );
}
