import classNames from 'classnames';
import './PageSimple.css';


export const PageSimple = (props: { className?: string, children: React.ReactNode }) => {
  const { children } = props;

  return (
    <>
      {children}
    </>
  );
}