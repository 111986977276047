import React from 'react';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { ProgramItemType } from '../types/Program';
import './ProgramMenu.css';
import { MenuConfigType } from '../types/Config';

/*
"menugroup" is a group if menu "items"
*/

export function ProgramMenugroup(props : { item: ProgramItemType, allItems: ProgramItemType[], menuConfig: MenuConfigType[] }) {
  const navigate = useNavigate();
  const { itemId: urlItemId = 1 } = useParams();

  const { item, allItems, menuConfig } = props;
  const { title, id } = item;
  const childItems = allItems.filter(s => s.parent_id === id);

  function handleSelect(item: ProgramItemType) {
    const newUrl = `/program/${item.id}`;
    navigate(newUrl);
  }

  function isSelected() : boolean {
    const selectedItem = allItems.filter(it => it.parent_id === item.id).find(it => it.id == urlItemId || it.parent_id == urlItemId);
    return !!selectedItem;
  }

  const clItemgroup = {
    'program-menugroup': 1,
    'program-menugroup_selected': isSelected(),
    'program-menugroup_secondary': menuConfig.find(c => c.id === item.id)?.secondary,
  }

  const clItem = (itemId: number) => ({
    'program-menugroup__item': 1,
    'program-menugroup__item_secondary': menuConfig.find(c => c.id === itemId)?.secondary,
  });

  return (
    <div className={classNames(clItemgroup)}>
      <div className="program-menugroup__title" title={item.title} onClick={() => handleSelect(item)}>
        {item.id}. {title}
      </div>
      <div className="program-menugroup__items">
        {childItems.map(it => (
          <div key={it.id} className={classNames(clItem(it.id))} title={it.title} onClick={() => handleSelect(it)}>
            {it.title}
          </div>
        ))}
      </div>
    </div>
  );
}