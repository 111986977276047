import classNames from 'classnames';
import './Icon.css';

export function Icon(props : { name: string | null, className?: string, filled?: boolean }) {
  const { name, className, filled, ...rest } = props;
  const cl = {
    'material-icons': 1,
    'icon': 1,
    [className || '']: !!className,
  };
  return (
    <span className={classNames(cl)} {...rest}>{name}</span>
  );
};