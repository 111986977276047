import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from './containers/Home';
import { AppNopage } from './containers/AppNopage';
import { Program } from './containers/Program';
import { ProgramItemType } from './types/Program';
import './App.css';
import { example_menu_config, example_program_items } from './consts/exampleData';
import { MenuConfigType } from './types/Config';


function App() {
  const [menuItems, setMenuItems] = useState<ProgramItemType[]>([]);
  const [menuConfig, setMenuConfig] = useState<MenuConfigType[]>([]);

  useEffect(() => {
    setMenuItems(example_program_items);
    setMenuConfig(example_menu_config);
  }, []);

  return (
    <BrowserRouter>
      <div className="app" data-testid="app">
        <Routes>
          <Route index element={<Program menuItems={menuItems} menuConfig={menuConfig} />} />
          <Route path="home" element={<Home />} />
          <Route path="/program/:itemId?" element={<Program menuItems={menuItems} menuConfig={menuConfig} />} />
          <Route path="*" element={<AppNopage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
