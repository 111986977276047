import React, { useState } from 'react';
import classNames from 'classnames';
import { Icon } from './Icon';
import './Collapse.css';


export function Collapse({ children, label = 'View' }: { children: React.ReactNode, label?: string }) {
  const [open, setOpen] = useState<boolean>(false);

  function toggle() {
    setOpen(!open);
  }

  const cl = {
    
  };

  return (
    <div className='collapse'>
      <div onClick={toggle} className='collapse-toggle'>
        <Icon name={open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} />
        {label}
      </div>
      {open && (
          <div>
          {children}
        </div>
      )}
    </div>
  );
}
