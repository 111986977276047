import { MenuConfigType } from '../types/Config';
import { ProgramItemType, ProgramContentType, ProgramTaskType } from '../types/Program';


export const example_tasks_content : ProgramTaskType[] = [
  { id: 10301, item_id: 103, title: '', content: `
    Create an object that has 3 properties: "userID" with the value "jdoe", "userFullname" with value "John Doe" and "jobTitle" with value "Project Manager". 

  `},

  { id: 10501, item_id: 105, title: '', content: ``},
  { id: 10502, item_id: 105, title: '', content: ``},
  { id: 10503, item_id: 105, title: '', content: ``},
  { id: 10504, item_id: 105, title: '', content: ``},
  { id: 10505, item_id: 105, title: '', content: ``},
  { id: 10506, item_id: 105, title: '', content: ``},

  
  { id: 10521, item_id: 106, title: '', content: ``},
  { id: 10522, item_id: 106, title: '', content: ``},
  { id: 10523, item_id: 106, title: '', content: ``},
  { id: 10524, item_id: 106, title: '', content: ``},
  { id: 10525, item_id: 106, title: '', content: ``},
  { id: 10526, item_id: 106, title: '', content: ``},

  { id: 10511, item_id: 106, title: '', content: ``},
  { id: 10512, item_id: 106, title: '', content: ``},
  { id: 10513, item_id: 106, title: '', content: ``},
  { id: 10514, item_id: 106, title: '', content: ``},
  { id: 10515, item_id: 106, title: '', content: ``},
  { id: 10516, item_id: 106, title: '', content: ``},
  { id: 10517, item_id: 106, title: '', content: ``},
  { id: 10518, item_id: 106, title: '', content: ``},
  { id: 10519, item_id: 106, title: '', content: ``},

  { id: 20301, item_id: 203, title: '', content: `
    Create an HTML page. Add a text paragraph ("p" tab). Add different headers ("h1" - "h6" tags).
    Check indentation for all elements to make sure it reflects hierarchy of the elements.
  ` },
  { id: 20302, item_id: 203, title: '', content: `
    Add a link inside the text paragraph ("a" tag, any url).
    Check indentation for all elements to make sure it reflects hierarchy of the elements.
  ` },
  { id: 20303, item_id: 203, title: '', content: `
      Change the code from the previous task so all headers are wrapped in a div, and paragraph stays where it was. 
      Check indentation for all elements to make sure it reflects hierarchy of the elements.
  ` },
  { id: 20304, item_id: 203, title: '', content: ``},
  { id: 20305, item_id: 203, title: '', content: ``},
  { id: 20306, item_id: 203, title: '', content: ``},


  { id: 20401, item_id: 204, title: '', content: `
    Add styles.css file to your project folder and link it in HTML file. Change the body background color to "lightblue" or any other color.
  ` },

  { id: 20402, item_id: 204, title: '', content: `
    Overwrite default margin/padding on body element - set it to 0. 
    ` },

  { id: 20403, item_id: 204, title: '', content: `
    Add a "div" to your HTML file with a class "colored-bkg" and some text inside. Change the background color of the div using class selector. 
    ` },

  { id: 20404, item_id: 204, title: '', content: `
    Add another "div" with class "colored-text" and some text. Change color of the text. Add 1rem padding.
    ` },
  { id: 20405, item_id: 204, title: '', content: `
    Add a "div" and give it a class with any name. Set div height to 200px and width to 300px. Add background color to see the div on the page. Add 20px margin.
  `},

  { id: 20406, item_id: 204, title: '', content: `
    Add a paragraph ("p" tag) to the page and give it a class with any name. Make text inside the paragraph bold, also change font size to 1.2rem and line spacing to 2rem (google "CSS line spacing" to find the property name).
  `},
  { id: 20407, item_id: 204, title: '', content: `
    Add div to the page, move it to top right corner using "top", "right" and "position" properties.
  `},

  { id: 20310, item_id: 204, title: '', content: `
    Add div element with "class" and "id" attributes. Add padding to the div using class attribute, and background color using id attribute.
  `},
  { id: 20310, item_id: 204, title: '', content: `
    Which type of selector would you use to change style for all "p" elements on the page?
    Which type of selector would you use to change style for one "p" element?
  `},



  { id: 20701, item_id: 207, title: '', content: `
    Add a div with width 40vw and height 40vh. Center it vertically and horizontally on the page using top and left css properties. 
    (Create a new page for this task or a parent div for this task.) 
  `},
  { id: 20702, item_id: 207, title: '', content: `
    Add a div with width 40vw and height 40vh. Center it vertically and horizontally on the page using margin.
    (Create a new page for this task or a parent div for this task.) 
  `},
  { id: 20703, item_id: 207, title: '', content: `
    Add a div with width 40vw and height 40vh. Center it vertically and horizontally on the page using flex properties on the parent element. (Use cheatsheet to find solution with flexbox)
    (Create a new page for this task or a parent div for this task.) 
  `},

  { id: 20704, item_id: 207, title: '', content: `
    Add 3 divs with 20vw width. Display them inline. (Create a new page or a parent div for this task)
  `},
  { id: 20705, item_id: 207, title: '', content: `
    Add 4 divs and display them inline, so each takes a fourth of the screen width and they always feet no matter screen size.
   (Create a new page or a parent div for this task)
  `},

  { id: 20706, item_id: 207, title: '', content: `
    Add a div with a text inside (width 50vw height 50vh). Center text inside div horizontally using text-align.
  `},
  { id: 20707, item_id: 207, title: '', content: `
    Add a div with a text inside (width 50vw height 50vh). Center text inside the div horizontally + vertically using 'display:flex'.
  `},
  
  { id: 20708, item_id: 207, title: '', content: `
    Create a div for a top bar element. Add 4 divs with text links - 2 on the left and 2 on the right. Center text in all divs vertically and horizontally so it all shows on the same line.
  `},


  { id: 20731, item_id: 207, title: '', content: '', imageUrl: 'https://devclass-program.s3.us-east-2.amazonaws.com/image_5.png' },
  { id: 20732, item_id: 207, title: '', content: '', imageUrl: 'https://devclass-program.s3.us-east-2.amazonaws.com/image2.png' },
  { id: 20733, item_id: 207, title: '', content: '', imageUrl: 'https://devclass-program.s3.us-east-2.amazonaws.com/image_4.png' },
  { id: 20734, item_id: 207, title: '', content: '', imageUrl: 'https://devclass-program.s3.us-east-2.amazonaws.com/image_3.png' },
];

const imageMdExample = `
    <div style="width:100%;object-fit:cover;" class="markdown-image_fullwidth">
<img width="100%" src="https://devclass-program.s3.us-east-2.amazonaws.com/image_5.png">
</div>
`;