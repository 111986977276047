import React from 'react';
import classNames from 'classnames';
// import Markdown from 'react-markdown';
import Markdown from 'markdown-to-jsx';
import { ErrorBoundary } from './ErrorBoundary';
import { CodeBlock } from './CodeBlock';
import './MarkdownBlock.css';


function MarkdownCodeBlock(props: { block: string }) {
  const { block } = props;
  const lang = block.split('\r\n')[0] || '';
  const codestring = block.substring((lang + '\r\n').length);
  const langMap : { [key: string]: string } = { js: 'javascript', html: 'html' };
  const codelang = langMap[lang] || lang;
  return <CodeBlock language={codelang}>{codestring}</CodeBlock>;
}

export function MarkdownBlock(props: { children: string, className?: string }) {
  const { children = '', className = '' } = props;

  const blocks = children.split('```');

  const cl = {
    'markdown-block': 1,
    [className]: className,
  };

  return (
    <div className={classNames(cl)}>
      {blocks.map((block, i) => {
        if (i % 2  === 0) {
          return (
            <ErrorBoundary>
              <Markdown key={i}>{block}</Markdown>
            </ErrorBoundary>
          );
        }
        return (
          <ErrorBoundary>
            <MarkdownCodeBlock key={i} block={block} />
          </ErrorBoundary>
        );
      })}
    </div>
  );
}

